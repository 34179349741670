<template>
    <div class="order-payment-page my-5">
        <div class="container mb-5">
            <h1>Płatność</h1>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
            <div v-else>
                <div v-if="order">
                    Kwota do przelewu za zamówienie nr <strong id="orderID">{{order.orderID}}</strong> wynosi <strong id="sumPrice">{{Number(order.sumPrice).toLocaleString()}} PLN</strong>
                    <br />
                    <br />
                    Za chwilę zostaniesz przekierowany do płatności. 
                    <br />
                    <br />
                    <div class="ml-3">
                        <img src="@/assets/Przelewy24/Przelewy24_logo.svg" alt="przelewy24.pl" width="120" class="mr-4" />                         
                    </div>
                    <br />
                    <br />
                    <buttonLoader @click="goToPayment()" label="Przejdź do płatności" :isLoading="isButtonLoading" variant="success" class="px-4" />
                </div>
                <div v-else>
                    Brak zamówienia do zapłaty.
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "OrderPayment",
    data() {
        return {
            isLoading: true,
            isButtonLoading: false,
            order: null
        }
    },
    mounted(){
        this.$store.dispatch('order/getOrderInfo', this.$route.params.orderId ).then( (data) => {
            this.order = data;
            this.isLoading = false;
            if(this.order.orderID) {
                this.delayRedirectToPayment();
            }
        }).catch( () => {
            this.isLoading = false;
                this.$bvToast.toast(`Błąd pobierania danych do płatności`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                });
            });
    },
    methods: {
        delayRedirectToPayment() {
            setTimeout(() => this.goToPayment(), 10000);
        },
        goToPayment(){
            this.isButtonLoading = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/customer/payment/order/' + this.$route.params.orderId).then( (responce) => {
                this.isButtonLoading = false;
                window.location.replace( responce.data.redirectTo );
            }).catch( () => {
                this.isButtonLoading = false;
                this.$bvToast.toast(`Błąd przechodzenia do płatności`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                });
            });
        }
    }

};
</script>
